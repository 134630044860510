<template>
  <v-app-bar height="55" elevation="1" app>
    <v-app-bar-nav-icon @click="drawerHandler()" ></v-app-bar-nav-icon>
    <v-btn
      elevation=""
      color="primary"
      class="white--text"
      style="text-transform: unset"
      @click="$router.go(-1)"
    >
      <v-icon> mdi-arrow-left-circle </v-icon>
      <span>Back</span>
    </v-btn>

    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
          style="cursor: pointer"
          class="mx-5 mr-10"
        >
          <v-chip link pill>
            <v-badge dot bottom offset-x="10" offset-y="10" color="green">
              <v-icon>mdi-account-circle</v-icon>
            </v-badge>
            
            <div>
            <span class="mx-2">{{ user.username }}</span>
            </div>
            <h2>
            </h2>
          </v-chip>
        </span>
      </template>
      <v-list width="250" class="py-0">
        <v-list-item two-line>
          <!-- <v-list-item-avatar> -->
            <!-- <v-icon>mdi-account-circle</v-icon> -->
            <!-- <v-img src="" />
          </v-list-item-avatar> -->
          <v-list-item-content>
            <div class="text-center" v-if="accountDetails.accountType == 'Institutional Account'">
              <h3 class="primary--text font-weight-bold">{{accountDetails.institutionName}}</h3>
            </div>
            <div class="text-center" v-if="accountDetails.accountType == 'Personal Account'">
              <h3 class="primary--text">{{user.username}}</h3>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <!------->
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-dialog v-model="dialog1" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <h4
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  style="text-transform: unset; color: black"
                >
                  Profile
                </h4>
              </template>

              <v-card
                class="mx-auto"
                max-width="500"
                style="border-radius: 5px"
              >
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-dialog v-model="dialog2" persistent width="500">
                        <template v-slot:activator="{ on: dialog2, attrs }">
                          <v-tooltip bottom color="primary">
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                color="primary"
                                elevation="10"
                                dark
                                fab
                                v-bind="attrs"
                                v-on="{ ...tooltip, ...dialog2 }"
                              >
                                <v-icon size="30"> mdi-account-edit</v-icon>
                              </v-btn>
                            </template>
                            <span class="white--text">Edit profile</span>
                          </v-tooltip>
                        </template>
                        <v-card color="rgba(255,255,255,1)" width="500">
                          <v-card-title class="text-h5 text-center">
                            <h5 class="text-center primary--text">
                              Edit profile
                            </h5>
                          </v-card-title>

                          <v-card-text>
                            <v-text-field
                              label="First Name"
                              v-model="user.firstName"
                              outlined
                              :value="user.firstName"
                              dense
                            />

                            <v-text-field
                              label="Last Name"
                              v-model="user.lastName"
                              outlined
                              dense
                              :value="user.lastName"
                            />
                            <v-text-field
                              outlined
                              label="Username"
                              dense
                              v-model="user.username"
                              :value="user.username"
                            />
                            <v-text-field
                              outlined
                              label="Email"
                              dense
                              v-model="user.email"
                              :value="user.email"
                            />
                          </v-card-text>

                          <v-card-text> </v-card-text>
                          <v-card-actions class="mx-3">
                            <v-spacer></v-spacer>
                            <v-btn
                              style="text-transform: unset"
                              color="red"
                              class="white--text"
                              @click="dialog2 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              style="text-transform: unset"
                              color="primary"
                              @click="editProfileHandler(user)"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <div class="text-center" style="margin-top: -12%">
                        <v-avatar color="" size="100">
                          <v-img
                            src="../../assets/images/user2.svg"
                            width="100"
                            height="100"
                            class="filter-blue"
                          />
                        </v-avatar>
                      </div>
                      <v-divider class="my-5"></v-divider>
                      <div>
                        <v-row>
                          <v-col cols="6">
                            <span class="font-weight-bold">First name:</span
                            ><span class="ml-3">{{ user.firstName }}</span>
                          </v-col>
                          <v-col cols="6">
                            <span class="font-weight-bold">Last name:</span
                            ><span class="ml-3">{{ user.lastName }}</span>
                          </v-col>
                          <v-col cols="6">
                            <span class="font-weight-bold">Username:</span
                            ><span class="ml-3">{{ user.username }}</span>
                          </v-col>
                          <v-col cols="6">
                            <span class="font-weight-bold">Email:</span
                            ><span class="ml-3">{{ user.email }}</span>
                          </v-col>
                        </v-row>
                      </div>
                      <v-divider class="my-3"></v-divider>
                      <div class="text-right">
                        <v-row>
                          <v-col>
                            <v-btn
                              color="red"
                              class="white--text"
                              style="text-transform: unset"
                              @click="dialog1 = false"
                              >Close</v-btn
                            >
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-key</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-dialog v-model="dialog" persistent max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <span
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                  style="text-transform: unset; color: black"
                >
                  Change password
                </span>
              </template>

              <v-card
                class="mx-auto"
                max-width="500"
                style="border-radius: 5px"
              >
                <v-card-title class="primary--text"
                  >Change password</v-card-title
                >

                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-form>
                        <v-text-field
                          v-model="oldPassword"
                          dense
                          label="Enter old password"
                          prepend-inner-icon="mdi-lock"
                          outlined
                          required
                          :value="mypass"
                          :append-icon="
                            value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                          "
                          @click:append="() => (value = !value)"
                          :type="value ? 'password' : 'text'"
                        />
                        <!-- <v-text-field
                          v-model="oldPassword"
                          type="password"
                          label="Enter old password"
                          dense
                          outlined
                        /> -->
                        <v-text-field
                          v-model="newPassword1"
                          dense
                          label="Enter new password"
                          prepend-inner-icon="mdi-lock"
                          outlined
                          required
                          :value="mypass"
                          :append-icon="
                            value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                          "
                          @click:append="() => (value = !value)"
                          :type="value ? 'password' : 'text'"
                        />

                        <!-- <v-text-field
                          v-model="newPassword1"
                          type="password"
                          label="Enter new password"
                          dense
                          outlined
                        /> -->
                        <!-- <v-text-field
                          v-model="newPassword2"
                          type="password"
                          label="Re-nter new password"
                          dense
                          outlined
                        /> -->
                        <v-text-field
                          v-model="newPassword2"
                          dense
                          label="Re-nter new password"
                          prepend-inner-icon="mdi-lock"
                          outlined
                          required
                          :value="mypass"
                          :append-icon="
                            value ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                          "
                          @click:append="() => (value = !value)"
                          :type="value ? 'password' : 'text'"
                        />
                      </v-form>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        class="mx-2 white--text"
                        color="red"
                        style="text-transform: unset"
                        @click="dialog = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        class="mx-1 white--text"
                        color="primary"
                        @click="changePasswordHandler"
                        style="text-transform: unset"
                        >Save</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link @click="logoutHandler">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              ><h4
                depressed
                color="white"
                left
                style="text-transform: unset; color: black"
              >
                Logout
              </h4></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Topnav",
  data: () => ({
    menus: [{ title: "Settings", icon: "mdi-cog" }],
    dialog: false,
    dialog1: false,
    dialog2: false,

    oldPassword: "",
    newPassword1: "",
    newPassword2: "",
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    pk: "",
    value: String,
    value1: String,
    mypass: "",
    hover:null
  }),
  computed: {
    ...mapGetters({
      user: "getLoggedUser",
      accountDetails:"getAccountDetails"
    }),
  },
  mounted: async function () {
    await this.$store.dispatch('fetchAccountDetails');
  },

  methods: {
    editProfileHandler(user) {
      var data = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        primaryKey: user.pk,
        username: user.username,
      };

      this.$store.dispatch("editUserDetails", data);
      this.dialog1 = false;
    },
    logoutHandler() {

      // console.log("The rfre",localStorage.getItem('refreshToken'));
      const data = {
        refreshToken: localStorage.getItem('refreshToken')
      }

      this.$store.dispatch("revokeToken", data);
      localStorage.removeItem("apollo-token");
 
      localStorage.removeItem("loggedUserEmail");
      localStorage.clear();
      this.$router.push("/");

      window.location.reload();
    },

    changePasswordHandler() {
      var data = {
        oldPassword: this.oldPassword,
        newPassword1: this.newPassword1,
        newPassword2: this.newPassword2,
      };

      this.$store.dispatch("changePassword", data);
      this.oldPassword = "";
      this.newPassword1 = "";
      this.newPassword2 = "";

      this.dialog = false;
    },
    drawerControler() {
      this.$emit("draw");
    },
    drawerHandler(){
      this.$store.commit("setDrawer");

    }
  },
};
</script>
<style scoped>
.filter-blue {
  filter: invert(33%) sepia(100%) saturate(3814%) hue-rotate(192deg)
    brightness(93%) contrast(102%);
}
</style>