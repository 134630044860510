<template>
  <v-app class="background">
    <Topnav />
    <Sidenav />
    <v-container fluid>
      <v-btn @click="PrintAnalysis()" style="text-transform: unset">
        Export PDF
      </v-btn>
      <v-btn @click="goViewAnswers()" style="text-transform: unset" class="mx-2">
        View answers
      </v-btn>
      <div id="surveyVizPanel" />
      <div id="toPrint" class="hidden"></div>
    </v-container>
    <v-container fluid>
      <v-row>
        <v-col>
          <div>
            <v-row>
              <v-col
                cols="6"
                v-for="surveyAnalytic in surveyAnalytics"
                :key="surveyAnalytic.questionName"
              >
                <v-card elevation="2">
                  <v-img
                    :src="filesRoot + surveyAnalytic.figPath"
                    width="700"
                    height="500"
                  />
                </v-card>
              </v-col>
            </v-row>
            <br />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Topnav from "@/components/navigators/Topnav";
import Sidenav from "@/components/navigators/Sidenav";
import { mapGetters } from "vuex";
const filesRoot = process.env.VUE_APP_FILES_ROOT;
export default {
  name: "SurveyAnalytics",
  components: {
    Topnav,
    Sidenav,
  },
  data() {
    return {
      survey: null,
      filesRoot: process.env.VUE_APP_FILES_ROOT,
    };
  },
      beforeMount: async function(){

    await this.$store.dispatch('loadSurveyPayload', this.$route.params.surveyId);

      let surveyContent = await this.fetchSurveyPayload(
      filesRoot + this.$store.getters.getSurveyPayload
    );

    this.$store.commit('setSurveyContent', surveyContent);
    

  },
  async mounted() {
    let surveyId = this.$route.params.surveyId;

    await this.$store.dispatch("fetchSurveyAnalytics", {
      surveyUniqueId: surveyId,
    });
  },
  methods: {
    async PrintAnalysis() {
      await this.$store.dispatch("fetchSurveyAnalyticsPdf", {
        surveyUniqueId: this.$route.params.surveyId,
      });
    },
    async fetchSurveyPayload(url) {
      let response = await fetch(url);
      let data = response.text();

      return data;
    },

    goViewAnswers: async function(){

      // await this.$store.dispatch("loadSurveyAnswers", surveyId);

      // await this.$store.dispatch("loadSurveyPayload", surveyId);


      var surveyId = this.$route.params.surveyId;
      // console.log('The survey id', surveyId)
      this.$router.push('/respondent-view/' + surveyId );
    }
  },

  computed: {
    ...mapGetters({
      surveyAnswers: "getSurveyAnswers",
      surveyAnalytics: "getSurveyAnalytics",
      surveyAnalyticsPdf: "getSurveyAnalyticsPdf",
    }),
  },
};
</script>

<style scoped>
</style>
