<template>
  <v-navigation-drawer
    :mini-variant.sync="this.$store.getters.getDrawer"
    :expand-on-hover="onHover"
    permanent
    app
    color="#f2f2f2"
  >
    <div class="items-center">
      <div style="margin: 15% !important; border: 1px solid grey; border-radius: 10px; background-color: white;">
        <div style="margin: 15% !important">
          <v-img src="../../assets/images/eDodoso_logo.png">
            <span class=""> </span>
            <div class="text-center">
            </div>
          </v-img>
        </div>
      </div>
    </div>
    <v-divider /><v-divider />
    <v-list dense >
      <v-list-item class="listItem" 
        link to="/system-admin-dashboard" 
        v-if="this.$store.getters.getDefaultUserRole == 'System Admin'"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-microsoft-windows</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="listItem" 
        link to="/institution-admin-dashboard" 
        v-if="this.$store.getters.getDefaultUserRole == 'Institution Admin'"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-microsoft-windows</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="listItem" 
        link to="/surveyor-dashboard" 
        v-if="this.$store.getters.getDefaultUserRole == 'Normal User'"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-microsoft-windows</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/survey-list">
        <v-list-item-icon>
          <v-icon>mdi-clipboard-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Survey management</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/quiz-list">
        <v-list-item-icon>
          <v-icon>mdi-lightbulb-on</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Quiz management</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/survey-categories">
        <v-list-item-icon>
          <v-icon >mdi-format-list-bulleted-type</v-icon>
        </v-list-item-icon>
        <v-list-item-title>survey & quiz categories</v-list-item-title>
      </v-list-item>

      <v-list-item
        class="listItem"
        link
        to="/institution-management"
        v-if="hasAccess('CAN_MANAGE_INSTITUTION')"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-home-city</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Manage institutions</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        class="listItem"
        link
        to="/manage-users"
        v-if="hasAccess('CAN_MANAGE_USER')"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Manage users</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        class="listItem"
        link
        to="/access-levels"
        v-if="hasAccess('CAN_MANAGE_ACCESS_LEVEL')"
      >
        <v-list-item-icon>
          <v-icon class="listIcon">mdi-shield-lock</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Access levels</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Sidebar",
  data() {
    return {
      links: [
        ["mdi-microsoft-windows", "Dashboard"],
        ["mdi-account", "Profile"],
        ["mdi-clipboard-list-outline", "Products"],
        ["mdi-card-account-details-outline", "Orders"],
        ["mdi-cog", "System Setting"],
      ],
      mini: null,
      onHover: null,
    };
  },
  computed: {
    ...mapGetters({
      drawer: "getDrawer",
    }),
  },
};
</script>